import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import NotFound from "../../components/NotFound/NotFound"
import Template from "../../components/Template/Template"
import { renderTags } from "../../utilities/renderTags"

export const pageQuery = graphql`
  {
    image: file(relativePath: { eq: "error404Bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    contentfulPage(node_locale: { eq: "es" }, slug: { eq: "404" }) {
      name
      ...pageFields
    }
  }
`

const NotFoundPage = ({ data, location }) => {
  const page = data.contentfulPage

  useEffect(() => {
    addGoogleAnalytics()
    addGoogleAnalyticsEvent()
  }, [])

  const addGoogleAnalytics = () => {
    const script = document.createElement("script")
    const scriptBody = `
      var global_clientId_analytics;
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

      ga('create', 'UA-79552282-1', 'auto', { 'allowLinker': true });
      ga('create', 'UA-79552282-3', { 'name': 'rollupProperty', 'allowLinker': true });

      ga('require', 'linker');
      ga('linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);
      ga('rollupProperty.require', 'linker');
      ga('rollupProperty.linker:autoLink', ['app.agicap.fr', 'content.agicap.fr', 'app.agicap.com', 'agicap.com']);

      ga('rollupProperty.require', 'GTM-594HVRW');

      ga('send', 'pageview');
      ga('rollupProperty.send', 'pageview');

      ga(function (tracker) {
        global_clientId_analytics = tracker.get('clientId');
      });
    `
    script.innerHTML = scriptBody
    document.body.appendChild(script)
  }

  const addGoogleAnalyticsEvent = () => {
    const script = document.createElement("script")
    const pathName = location.pathname
    const data = {
      hitType: "event",
      eventCategory: "Website Issue",
      eventAction: "404",
      eventLabel: pathName
    }
    script.innerHTML = `ga('send', ${JSON.stringify(data)});`
    document.body.appendChild(script)
  }

  const pageStartScriptTags = renderTags("es", "es", page.bodyStartScripts)
  const pageEndScriptTags = renderTags("es", "es", page.bodyEndScripts)

  const { image } = data
  const topMenuData = page.template

  return (
    <Layout>
      <SEO lang="es" title="404: Not found" includeSelfCanonical={true} />
      <NotFound
        image={image.childImageSharp}
        titleA="error"
        titleB="Esta página no existe"
        ctaURL="/es/"
        type="new"
        locale="es"
        defaultLocale="es"
        linkHome={{
          targetUrl: "/es/",
          title: "Volver a la página de inicio",
          displayStyle: "primaryButton"
        }}
      />
      <Template
        {...topMenuData}
        isMenuFixed={page.fixedMenu}
        locale="es"
        defaultLocale="es"
      >
        {pageStartScriptTags}
        {pageEndScriptTags}
      </Template>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default NotFoundPage
